import { URLs } from "../api/URLs.js";
import axios from "../api/axios.js";

export const fetch_items = async (token, params) => {
  const response = await axios
    .get(URLs.FETCH_ITEMS, {
      params: { company: params.company, branch: params.branch },
      headers: { "Content-Type": "application/json", "x-auth-token": token },
      withCredentials: true,
    })
    .then((res) => {
      console.log(res?.data?.msg?.company, "stuff");
      return res?.data?.msg;
    })
    .catch((error) => {
      console.log(error, "error getting company and branches");
      throw error;
    });
  return response;
};

export const loginCustomer = async (params) => {
  const response = await axios
    .post(
      URLs.LOGIN,
      JSON.stringify({
        phone: params.phoneNumber,
        password: params.password,
      }),
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
  return response;
};
export const registerCustomer = async (params) => {
  const response = await axios
    .post(
      URLs.REGISTER,
      JSON.stringify({
        name: params.name,
        phone: params.phoneNumber,
        newpassword: params.password,
        otp:params.otp
      }),
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
  return response;
};

export const addToCart = async (token, params) => {
  const response = await axios
    .post(
      URLs.ADD_TO_CART,
      JSON.stringify({
        item_id: params.item_id,
        variation_id: params.variation_id,
        addon: params.addon,
        specialInstruction: params.specialInstruction,
        quantity: params.quantity,
        branch:params.branch

      }),
      {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": token,
        },
        withCredentials: true,
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
  return response;
};

export const deleteCart = async (token, params) => {
  const response = await axios
    .delete(URLs.DELETE_CART, {
      params: { id: params.id },
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
      withCredentials: true,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
  return response;
};
export const fetchOrderMethod = async (token, params) => {
  const response = await axios
    .get(URLs.GET_ORDER_METHODS, {
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
      withCredentials: true,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
  return response;
};
export const placeOrder = async (token, params) => {
  console.log(params.cartItems);
  const response = await axios
    .post(
      URLs.PLACE_ORDER,
      JSON.stringify({
        cartItems: params.cartItems,
        orderMethod: params.orderMethod,
        paymentMethod: params.paymentMethod,
        orderLocation: params.orderLocation,
        branch:params.branch, 
        // otp:params.otp, phone:params.phone,
        hasCoupon:params.hasCoupon,
        couponText:params.couponText,
        company_id:params.company_id
      }),
      {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": token,
        },
        withCredentials: true,
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
  return response;
};
export const getChapaPaymentLink = async (token, params) => {
  console.log(params);
  const response = await axios
    .post(
      URLs.CHAPA_PAYMENT,
      JSON.stringify(params),
      {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": token,
        },
        withCredentials: true,
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
  return response;
};
export const verifyChapaPayment = async (token, params) => {
  console.log(params);
  const response = await axios
    .post(
      URLs.VERIFY_CHAPA_PAYMENT,
      JSON.stringify({
        order_method: params.orderMethod,
        order_location: params.orderLocation,
        tx_ref: params.tx_ref,
      }),
      {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": token,
        },
        withCredentials: true,
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
  return response;
};
export const checkPhone = async (params) => {
  const response = await axios
    .get(
      URLs.CHECK_PHONE,

      {
        params: {
          phone:params.phone
        },
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      console.log("check phonenumber services");

      return response.data;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
  return response;
};

export const checkCouponAvailability = async (token,params) => {
  const response = await axios
    .post(
      URLs.CHECK_COUPON,
      JSON.stringify( {
        coupon_id:params.coupon_id,
        company_id:params.company_id,
      }),
      {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": token,
        },
        withCredentials: true,
      }
    )
    .then((response) => {
      console.log("check coupon services");

      return response.data;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
  return response;
};
export const forgotPassword = async (params) => {
  const response = await axios
    .post(
      URLs.CHANGE_PASSWORD,
      JSON.stringify({ 
        phone: params.phone,
        newPassword: params.newPassword,
        otp:params.otp
      }),
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
  return response;
};