import { Button, Dialog } from "@mui/material";
import React, { useEffect, useLayoutEffect, useState } from "react";
import CustomTextfield from "../../CommonWidgets/CustomTextfield";
import { Close } from "@mui/icons-material";
import { URLs } from "../../api/URLs";
import axios from "../../api/axios";
import { useSelector } from "react-redux";

function ConfirmActionByOtp({handleOnClose, action, primaryColor, secondaryColor, phone,actionText}) {
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [remainingTime, setRemainingTime] = useState(0);
  const [isCounting, setIsCounting] = useState(false);
  const [otp, setOTP] = useState("");
  const phoneNumber = useSelector(state=>state.customer?.phone);
  const [axiosCalled, setAxiosCalled] = useState(false)

  useLayoutEffect(()=>{
    if(!axiosCalled){
    handleSendOtp()}
  },[])

  useEffect(() => {
    if (isCounting) {
      const intervalId = setInterval(() => {
        const diff = remainingTime - 1000;
        setRemainingTime(diff);
        if (diff < 1000) {
          setIsCounting(false);
        }
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [remainingTime]);

  const hours = Math.floor(remainingTime / (1000 * 60 * 60));
  const minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);

  const handleSendOtp = async () => {
    setAxiosCalled(true)
    await axios
      .post(
        URLs.SEND_OTP,
        JSON.stringify({
          phone: phoneNumber??phone,
          token: "generated_token",
        }),
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      .then((rez) => {
        console.log(rez);
        if (rez.data.status) {
          console.log(rez.data.status, " is the result of otp");
          setIsOtpSent(true);
          setRemainingTime(2000 * 60);
        } else {
          setIsOtpSent(true);
          setRemainingTime(rez.data.msg);
        }
        setIsCounting(true);
      })
      .catch((e) => console.log("error while sending otp"));
  };
  return (
    
    <Dialog
      closeOnEscape={true}
      open={true}
      fullWidth
      PaperProps={{ sx: { height: "fit-content", borderRadius: "8px" } }}
    >
      <div className="w-full flex flex-col py-4 bg-color-whites">
        <div className="pt-1 pb-4 px-4">
          <div className="flex flex-row justify-end">
            {" "}
            <Close
              onClick={() => handleOnClose(false)}
              className="cursor-pointer"
            />
          </div>
        </div>
        <hr className="pb-2" />
         
        <div className="pt-4 pb-2 flex justify-center items-center text-lg font-bold">
         {`SMS has been sent to ${phoneNumber??phone}`}
        </div>

        <div className="py-2 px-8 flex items-center gap-2">
          <CustomTextfield
            required={true}
            label={"OTP"}
            placeholder={"OTP recieved from"}
            value={otp}
            onChange={(e) => {
              if (e.target.value.length < 11) setOTP(e.target.value);
            }}
          />
          <div className="p-2 w-20 flex justify-center border-gray border-2 rounded-lg">
            {isCounting ? (
              <div> {`${minutes} : ${seconds} `}</div>
            ) : (
              <Button
               
                onClick={() => handleSendOtp()}
                sx={{
                  width: "fit-content",
                  padding: 0,
                  fontSize: ".7rem",
                }}
              >
                {isOtpSent?"Resend OTP":"Send OTP"}
              </Button>
            )}
          </div>
          
        </div>
        {/* <div className="py-2 px-8 flex items-center gap-2 justify-end"> 
        <Button
                disabled={
                  String(phoneNumber).trim().length != 10
                }
                onClick={() => handleSendOtp()}
                sx={{
                  width: "fit-content",
                  padding: 0,
                  fontSize: ".7rem",
                }}
              >
               PLACE ORDER
              </Button></div> */}
              <div className=" py-4  flex justify-center  mobile-width">
            <Button
              sx={{
                background: primaryColor,

                color: secondaryColor,
                border: `2px solid ${primaryColor}`,
                marginRight: "10px",
                paddingRight: ".8rem",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                // gap: "5px",
                height: "fit-content",
                "&:hover": { background: secondaryColor, color: primaryColor },
                "&.Mui-disabled": {
                  background: "#eaeaea !important",
                  color: "#c0c0c0",
                  border: "0px !important",
                },
              }}
              disabled={
                String(phoneNumber??phone).trim().length != 10
              }
           
              onClick={()=>action(otp, phoneNumber)}
            >
            {actionText??"Place Order"}
            </Button>
            </div>
      </div>
    </Dialog>
   
  );
}

export default ConfirmActionByOtp;
