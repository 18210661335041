import React, { useEffect, useState } from "react";
import { fetch_items, verifyChapaPayment } from "../Service/Menu_Services";
import { useDispatch, useSelector } from "react-redux";
import "./Menu.css";
import WorkInProgress from "../CommonWidgets/WorkInProgress";
import Loading from "../CommonWidgets/Loading";
import MenuHeader from "./MenuHeader";
import MenuTab from "./Tabs/MenuTab";
import CartCounter from "../Components/CartCounter/CartCounter";
import Cart from "./Tabs/Cart";
import ApiResult from "../CommonWidgets/ApiResult";
import { setCart } from "../state";
import { useNavigate } from "react-router-dom";
import Confirmation from "./Tabs/Confirmation";
import ThanksForOrdering from "../CommonWidgets/ThanksForOrdering";
import Reservation from "./Tabs/Reservation";
function Menu() {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const [items, setItems] = useState([]);
  const [company, setCompany] = useState(null);
  const [services, setServices] = useState([]);

  const [tabLocation, setTabLocation] = useState("menu");
  const [isEmpty, setIsEmpty] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [isChapaVerified, setIsChapaVerified] = useState(false);

  const cart = useSelector((state) => state.cart);

  const getItems = async () => {
    setIsLoading(true);
    const params = {
      company: getQueryVariable("company"),
      branch: getQueryVariable("branch"),
    };

    try {
      const data = await fetch_items(token, params);
      console.log(data, "itemsss")
      const itemsData = data.items.sort((a,b) =>{ 
        return a.category_order - b.category_order});
      const companyData = data.company;
      const services = data.services;
      setItems(itemsData);
      setCompany(companyData);
      console.log(companyData)
      setServices(services);
      if (data.length) {
        setIsEmpty(true);
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (
      getQueryVariable("order_method") &&
      token.length &&
      getQueryVariable("company") &&
      getQueryVariable("branch") &&
      getQueryVariable("order_location")
    ) {
      setIsLoading(true);

      verifyPayment();
    } else {
      getItems();
    }
    // return;
  }, []);

  const verifyPayment = async () => {
    try {
      const verification = await verifyChapaPayment(token, {
        orderMethod: getQueryVariable("order_method"),
        orderLocation: getQueryVariable("order_location"),
        tx_ref: getQueryVariable("tx_ref"),
      });
      console.log(verification, "verification purpose");
      dispatch(
        setCart({
          cart: [],
        })
      );
      await getItems();
      setIsChapaVerified(true);
    } catch (err) {
      console.log(err, "error in verify chapa");
      // await getItems();
    }
    navigate(
      `/menu?company=${getQueryVariable(
        "company"
      )}&amp;branch=${getQueryVariable("branch")}`
    );
  };

  return (
    <>
      {isChapaVerified ? (
        // <ApiResult
        //   message={"Orders placed Succesfully"}
        //   handleClose={() => setIsChapaVerified(false)}
        //   isSuccess
        // />
        <ThanksForOrdering
          isOpen={isChapaVerified}
          handleOnClose={() => setIsChapaVerified(false)}
          primaryColor={company?.primary_color}
          secondaryColor={company?.secondary_color}
        />
      ) : null}
      {cart ? (
        cart.length && tabLocation !== "reservation" && tabLocation !== "cart" && !isLoading ? (
          <div onClick={() => setTabLocation("cart")}>
            <CartCounter
              companyPrimaryColor={company?.primary_color}
              companySecondaryColor={company?.secondary_color}
              cart={cart.length}
            />
          </div>
        ) : null
      ) : null}
      {isEmpty && <WorkInProgress />}
      {!getQueryVariable("company") && <WorkInProgress />}
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className="menu-header">
            {company ? (
              <>
                <MenuHeader
                  image={company.image}
                  opening={company.opening_hour}
                  closing={company.closing_hour}
                  address={company.branch_address}
                  primaryColor={company?.primary_color}
                  secondaryColor={company?.secondary_color}
                  tabLocation={tabLocation}
                  handleChangeTabLocation={(tab) => setTabLocation(tab)}
                  map_location={company?.map_location}
                />
                {tabLocation == "menu" && (
                  <MenuTab
                    image={company.image}
                    items={items}
                    primaryColor={company.primary_color}
                    secondaryColor={company.secondary_color}
                    isLoading={(isOpen) => {
                      setIsLoading(isOpen);
                    }}
                    services={services}
                  />
                )}
                {/* <Confirmation/> */}
          {tabLocation == "reservation" ? (
            <Reservation
              primaryColor={company?.primary_color}
              secondaryColor={company?.secondary_color}
              companyName={company?.name}
            />
          ) : null}
          {tabLocation == "cart" ? (
            <Cart
              image={company.image}
              handleGoBackToMenu={() => setTabLocation("menu")}
              isLoading={(value) => setIsLoading(value)}
              primaryColor={company?.primary_color}
              secondaryColor={company?.secondary_color}
              services={services}
              cashPayment={company?.cash_payment}
              chapaPayment={company?.chapa_payment}
              isVat={company?.is_vat}
              serviceCharge={company?.service_charge}
            />
          ) : null}
              </>
            ) : (
              <WorkInProgress />
            )}
          </div>
          
        </>
      )}
    </>
  );
}

export default Menu;

function getQueryVariable(variable) {
  var query = window.location.search.substring(1);
  var vars = query.split("&amp;");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (pair[0] == variable) {
      return pair[1];
    }
  }
  return false;
}
