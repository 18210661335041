import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import CartTable from "../../Components/CartTable/CartTable";
import {
  checkCouponAvailability,
  deleteCart,
  fetchOrderMethod,
  getChapaPaymentLink,
  placeOrder,
} from "../../Service/Menu_Services";
import Loading from "../../CommonWidgets/Loading";
import ApiResult from "../../CommonWidgets/ApiResult";
import Login from "./Login";
import { setCart } from "../../state";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import CustomSelector from "../../CommonWidgets/CustomSelector";
import CustomTextfield from "../../CommonWidgets/CustomTextfield";
import ThanksForOrdering from "../../CommonWidgets/ThanksForOrdering";
import ShortNote from "../../Components/ShortNote/ShortNote";
import ConfirmActionByOtp from "../../Components/ConfirmActionByOtp/ConfirmActionByOtp";

function Cart({
  image,
  handleGoBackToMenu,
  isLoading,
  services,
  primaryColor,
  secondaryColor,
  cashPayment,
  chapaPayment,
  isVat,
  serviceCharge,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const cart = useSelector((state) => state.cart);
  const token = useSelector((state) => state.token);
  const customer = useSelector((state) => state.customer);

  //   const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const handleDeleteCart = async (id) => {
    isLoading(true);
    await deleteCart(token, { id: id })
      .then((response) => {
        console.log(response, "delete response");
        dispatch(
          setCart({
            cart: response.msg,
          })
        );
        isLoading(false);
      })
      .catch((e) => {
        console.log(e, "error in delete cart");
        isLoading(false);
      });
  };

  const isAuth = Boolean(useSelector((state) => state.token));
  const [isLoginVisible, setIsLoginVisible] = useState(false);

  const [isLoginSuccess, setIsLoginSuccess] = useState(false);
  const [orderMethods, setOrderMethods] = useState(services);
  const [selectedOrderMethodId, setSelectedOrderMethodId] = useState(
    services.length ? services[0].id : 3
  );

  const [orderLocation, setOrderLocation] = useState("");
  const [totalPriceFlow, setTotalPriceFlow]=useState({
    afterCoupon:null, afterServiceCharge:null, afterTax:null, basePrice:99
  })


  const [paymentMethod, setPaymentMethod] = useState(cashPayment ? 0 : 1);
  const [isPlaceItemSuccess, setIsPlaceItemSuccess] = useState(false);
  const isFirstTime = useSelector((state) => state.isFirstTime);
  const [isPlacingLoading, setIsPlacingLoading] = useState(false);
  const [couponText, setCouponText] = useState("");
  const [couponStatus, setCouponStatus]=useState(0);//0-none 1-fetched 2-loading -1 no coupon
  const [couponPercent, setCouponPercent]=useState(0);

  useEffect(() => { 
    if(cart.length){
      setTotalPriceFlow(caluculateTotalPrice(cart,serviceCharge, isVat, couponPercent))
    }
  }, [

  ]);
//   useEffect(() => { 
//     if(cart.length){
//       caluculateTotalPrice(cart,serviceCharge, isVat, couponPercent)
//     }
//   }, [
// cart
//   ]);
  const handlePlaceOrder = async () => {
    console.log(couponStatus, "coupon status")
    setIsOtpConfirmationVisible(false);
    // isLoading(true);
    setIsPlacingLoading(true);
    await placeOrder(token, {
      cartItems: cart.map((e) => e.id),
      paymentMethod,
      orderMethod: selectedOrderMethodId,
      orderLocation: selectedOrderMethodId==="6"?1000:orderLocation,
      // otp,
      // phone,
      hasCoupon: couponStatus===1,
      couponText,
      company_id:getQueryVariable("company"),
      totalPrice:totalPriceFlow.basePrice
      
    })
      .then((response) => {
        setIsPlaceItemSuccess(true);
        setIsPlacingLoading(false);
        dispatch(
          setCart({
            cart: [],
          })
        );
      })
      .catch((e) => {
        isLoading(false);
        setIsPlacingLoading(false);
        setIsOtpFailure(true);
        console.log(e, "error in place order");
      });
  };
  const getChapaPaymnt = async () => {
    isLoading(true);

    await getChapaPaymentLink(token, {
      phone: customer.phone,
      order_method: selectedOrderMethodId,
      order_location:orderLocation,
      paymentMethod, 
      cartItems: cart.map((e) => e.id),
      hasCoupon: couponStatus===1,
      couponText,
      company_id:getQueryVariable("company"),
      baseAmount:totalPriceFlow.basePrice
    })
      .then((response) => {
        // console.log(response, "resposne of plca order")
        // setIsPlaceItemSuccess(true);
        // dispatch(
        //   setCart({
        //     cart: [],
        //   })
        // );
        console.log(
          JSON.parse(response.msg),
          "response from chapaaaaaa"
        );
        // navigate(JSON.parse(response.msg).data.checkout_url)
        window.location.href = JSON.parse(response.msg).data.checkout_url;
      })
      .catch((e) => {
        isLoading(false);
        console.log(e, "error in chapaa get link");
      });
  };
  const checkCoupon = async (value) => {
    setCouponPercent(0)

    if(value==""||value.length<12){
      return;} 
    setCouponStatus(2)
    await checkCouponAvailability(token, {
      coupon_id:value,
     company_id:getQueryVariable("company")
    })
      .then((response) => {
       
        console.log(
          response
        );
        if(
          response.Error==false
        ){
          setCouponPercent(response.Message)
          setCouponStatus(1)

        }
         
      })
      .catch((e) => {
        console.log(e, "error in checking coupon");
      });
  };
  const [isOtpConfirmationVisible, setIsOtpConfirmationVisible] =
    useState(false);
  const [isOtpFailure, setIsOtpFailure] = useState(false);
  return (
    <>
      {isOtpFailure ? (
        <ApiResult
          message={"Phone authentication failed"}
          handleClose={() => setIsOtpFailure(false)}
        />
      ) : null}
      {/* {isOtpConfirmationVisible ? (
        <ConfirmActionByOtp
          handleOnClose={() => {
            setIsOtpConfirmationVisible(false);
          }}
          primaryColor={primaryColor}
          secondaryColor={secondaryColor}
          action={handlePlaceOrder}
        />
      ) : null} */}
      {isPlacingLoading ? <Loading full /> : null}
      {!isAuth ? (
        <Login
          isFirstTime={isFirstTime}
          companyLogo={image}
          handleOnClose={(success) => {
            if (success) {
              setIsLoginSuccess(true);
            } else {
              setIsLoginVisible(false);
              handleGoBackToMenu();
            }
          }}
        />
      ) : null}

      {isPlaceItemSuccess ? (
        <ThanksForOrdering
          isOpen={isPlaceItemSuccess}
          handleOnClose={() => handleGoBackToMenu()}
          primaryColor={primaryColor}
          secondaryColor={secondaryColor}
        />
      ) : null}
      {isLoginSuccess ? (
        <ApiResult
          message={"Logged in succesfully"}
          handleClose={() => setIsLoginSuccess(false)}
          isSuccess
        />
      ) : null}
      {!isAuth || !cart.length ? (
        <div className="w-full text-center h-96 flex justify-center items-center">
          <h1 className="italic">Empty Cart!</h1>
        </div>
      ) : (
        <div className="flex flex-col w-full h-auto items-center p-4">
          <div className="max-w-xs w-40">
            <img className="h-full w-full" src={image} />
          </div>
          <p className="p-2 font-bold text-base">Review and place order</p>
          <div className="w-2/4 p-2 cart-table">
            <CartTable
              primaryColor={primaryColor}
              secondaryColor={secondaryColor}
              handleDeleteCart={handleDeleteCart}
              rows={cart.map((e) => {
                console.log(e,"cart logs")
                return {
                  id: e.id,
                  name: e.item_name,
                  quantity: e.quantity,
                  price:
                    e.price +
                    e.addons
                      .map((f) => f.price)
                      .reduce((partialSum, a) => partialSum + a, 0),
                  action: e,
                };
              })}
            />
          </div>
          <div className="w-2/4 p-2 mobile-width">
            <CustomTextfield
              // type={selectedOrderMethodId === 2 ? "number" : "text"}
              multiline
              label={"Enter Promo Code"}
              value={couponText}
              onChange={(e) => {
                setCouponText(e.target.value);
                checkCoupon(e.target.value);
              }}
            />
          </div>
          <div className="w-2/4 p-2 flex justify-end mobile-width ">
            <p className="font-bold pr-2">Subtotal</p>{" "}
           
            <p className="pl-2">
            
              {
                totalPriceFlow.basePrice.toFixed(2)
              }
            </p>
            <p className="px-2">ETB</p>
          </div>
          {serviceCharge === 0 ? null : (
            <div className="w-2/4 p-2 flex justify-end mobile-width ">
              <p className="font-bold pr-2">{"Service Charge:"}</p>{" "}
              {"(" + serviceCharge + "%)"}
              <p className="pl-2">
                {/* {Math.ceil(
                  cart
                    .map((e) => {
                      return (
                        (e.price +
                          e.addons
                            .map((f) => f.price)
                            .reduce((partialSum, a) => partialSum + a, 0)) *
                        e.quantity
                      );
                    })
                    .reduce((partialSum, a) => partialSum + a, 0) *
                    (serviceCharge / 100) *
                    10

                ) / 10} */}
                {
                  totalPriceFlow.basePrice * serviceCharge/100
                }
              </p>
              <p className="px-2">ETB</p>
            </div>
          )}
          <div className="w-2/4 p-2 flex justify-end mobile-width ">
            <p className="font-bold pr-2">{isVat ? "VAT:" : "ToT:"}</p>{" "}
            {isVat ? "(15%)" : "(10%)"}
            <p className="pl-2">
              {/* {Math.ceil(
                cart
                  .map((e) => {
                    return (
                      (e.price +
                        e.addons
                          .map((f) => f.price)
                          .reduce((partialSum, a) => partialSum + a, 0)) *
                      e.quantity
                    );
                  })
                  .reduce((partialSum, a) => partialSum + a, 0) *
                  (isVat ? 0.15 : 0.1) *
                  10
              ) / 10} */}
              {
                (totalPriceFlow.basePrice * (isVat ? 0.15 : 0.1)).toFixed(2)
              }
            </p>
            <p className="px-2">ETB</p>
          </div>
          
          <div className="w-2/4 p-2 flex justify-end  mobile-width">
            <p className="font-bold pr-2">Total:~</p>
            ({(cart
              .map((e) => {
                return (
                  (e.price +
                    e.addons
                      .map((f) => f.price)
                      .reduce((partialSum, a) => partialSum + a, 0)) *
                  e.quantity
                );
              })
              .reduce((partialSum, a) => partialSum + a, 0) +
              Math.ceil(
                cart
                  .map((e) => {
                    return (
                      (e.price +
                        e.addons
                          .map((f) => f.price)
                          .reduce((partialSum, a) => partialSum + a, 0)) *
                      e.quantity
                    );
                  })
                  .reduce((partialSum, a) => partialSum + a, 0) *
                  (isVat ? 0.15 : 0.1)
              ) +
              Math.ceil(
                cart
                  .map((e) => {
                    return (
                      (e.price +
                        e.addons
                          .map((f) => f.price)
                          .reduce((partialSum, a) => partialSum + a, 0)) *
                      e.quantity
                    );
                  })
                  .reduce((partialSum, a) => partialSum + a, 0) *
                  (serviceCharge / 100)
              ))-((cart
                .map((e) => {
                  return (
                    (e.price +
                      e.addons
                        .map((f) => f.price)
                        .reduce((partialSum, a) => partialSum + a, 0)) *
                    e.quantity
                  );
                })
                .reduce((partialSum, a) => partialSum + a, 0) 
                +
                Math.ceil(
                  cart
                    .map((e) => {
                      return (
                        (e.price +
                          e.addons
                            .map((f) => f.price)
                            .reduce((partialSum, a) => partialSum + a, 0)) *
                        e.quantity
                      );
                    })
                    .reduce((partialSum, a) => partialSum + a, 0) *
                    (isVat ? 0.15 : 0.1)
                ) +
                Math.ceil(
                  cart
                    .map((e) => {
                      return (
                        (e.price +
                          e.addons
                            .map((f) => f.price)
                            .reduce((partialSum, a) => partialSum + a, 0)) *
                        e.quantity
                      );
                    })
                    .reduce((partialSum, a) => partialSum + a, 0) *
                    (serviceCharge / 100)
                ))*(couponPercent/100))})
            <p className="px-2">ETB</p>
            {
              couponStatus==1?
              <p className="px-2 text-green-700">{`(-${couponPercent}%)`}</p>:null}
          </div>
          <div className="w-2/4 p-2 flex justify-center mobile-width mt-8">
            <FormControl>
              <p className="font-bold pr-2 text-center py-2">Order Method</p>

              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                className="gap-4"
                value={selectedOrderMethodId}
                onChange={(event) => {
                  console.log(event.target.value);

                  setSelectedOrderMethodId(event.target.value);
                }}
              >
                {orderMethods && orderMethods.length
                  ? orderMethods.map((e) => (
                      <FormControlLabel
                        value={e.id}
                        control={
                          <Radio
                            sx={{
                              color: primaryColor,
                              "&.Mui-checked": {
                                color: primaryColor,
                              },
                            }}
                          />
                        }
                        label={e.name}
                      />
                    ))
                  : null}
              </RadioGroup>
            </FormControl>
          </div>
          {services && services.length  ? (
            <>
              { selectedOrderMethodId!=="6"?<div className={"w-2/4 p-2 mobile-width "}>
                <CustomTextfield
                  
                  type={(selectedOrderMethodId === 2) ? "number" : "text"}
                  multiline
                  label={
                    orderMethods.filter(
                      (service) => service.id == selectedOrderMethodId
                    )[0]?.location_type
                  }
                  value={orderLocation}
                  onChange={(e) => {
                    console.log(selectedOrderMethodId);
                    if (
                      (e.target.value.trim() === "" ||
                        regex.test(e.target.value)) &&
                      e.target.value.length < 15
                    ) {
                      setOrderLocation(e.target.value);
                    } else if (
                      (e.target.value.trim() === "" ||
                        selectedOrderMethodId == 2) &&
                      e.target.value.length < 15
                    ) {
                      setOrderLocation(e.target.value);
                    }
                  }}
                  //  required
                />
              </div>:null}
              <div className="w-2/4 p-2 mobile-width">
                {cashPayment || chapaPayment ? (
                  <CustomSelector
                    required={true}
                    label={"Select Payment Methods"}
                    value={paymentMethod}
                    onChange={(e) => {
                      setPaymentMethod(e.target.value);
                    }}
                    menuItems={
                      cashPayment && chapaPayment
                        ? [
                            { id: 0, name: "Cash" },
                            { id: 1, name: "Chapa" },
                          ]
                        : cashPayment
                        ? [{ id: 0, name: "Cash" }]
                        : chapaPayment
                        ? [{ id: 1, name: "Chapa" }]
                        : []
                    }
                  />
                ) : (
                  <div className="w-full flex justify-end p-4 mobile-width">
                    <ShortNote
                      text={
                        "Sorry, there are no payment option available. Please try again later. "
                      }
                    />
                  </div>
                )}
              </div>
            </>
          ) : (
            <div className="w-2/4 flex justify-end p-4 mobile-width">
              <ShortNote
                text={"Sorry, we are not taking any orders at the moment"}
              />
            </div>
          )}
          
          <p className="text-red ">
           {

          }
          </p>
          <div className="w-2/4 flex justify-end pb-40 mobile-width">
            <Button
              sx={{
                background: primaryColor,

                color: secondaryColor,
                border: `2px solid ${primaryColor}`,
                marginRight: "10px",
                paddingRight: ".8rem",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                // gap: "5px",
                height: "fit-content",
                "&:hover": { background: secondaryColor, color: primaryColor },
                "&.Mui-disabled": {
                  background: "#eaeaea !important",
                  color: "#c0c0c0",
                  border: "0px !important",
                },
              }}
              disabled={
                !cart ||
                !cart.length ||
                (String(orderLocation).trim() === "" && selectedOrderMethodId !=="6" )||
                !services ||
                !services.length ||
                (!cashPayment && !chapaPayment)
              }
              // onClick={
              //  ()=>{
              //   console.log(paymentMethod)
              //  }
              //   }
              onClick={
                paymentMethod == 1
                  ? getChapaPaymnt
                  // : () => setIsOtpConfirmationVisible(true)
                  :()=>handlePlaceOrder()
              }
            >
              Place Order
            </Button>
          </div>
        </div>
      )}
    </>
  );
}

export default Cart;
const regex = /^[0-9\b]+$/;
function getQueryVariable(variable) {
  var query = window.location.search.substring(1);
  var vars = query.split("&amp;");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (pair[0] == variable) {
      return pair[1];
    }
  }
  return false;
}


const caluculateTotalPrice=(cart, serviceCharge, isVat, couponPercent)=>{

  var afterTax = 0;
  var afterServiceCharge = 0;
  var afterCoupon = 0;
  var basePrice = cart
      .map((e) => {
        return (
          (e.price +
            e.addons
              .map((f) => f.price)
              .reduce((partialSum, a) => partialSum + a, 0)) *
          e.quantity
        );
      })
      .reduce((partialSum, a) => partialSum + a, 0);

  if(couponPercent){
    afterCoupon = basePrice - (basePrice* couponPercent/100)
  }else{
    afterCoupon = basePrice
  }

  if(serviceCharge){
    afterServiceCharge = afterCoupon + (afterCoupon* serviceCharge/100)
  }else{
    afterServiceCharge = afterCoupon
  }


  if(isVat){
    afterTax = afterServiceCharge + (afterServiceCharge* 0.15)
  }else{
    afterTax = afterServiceCharge + (afterServiceCharge* 0.1)
  }
  console.log({
    afterCoupon, afterServiceCharge, afterTax, basePrice
  })
  return {
    afterCoupon, afterServiceCharge, afterTax, basePrice
  }
   




}
