import { Close, Menu, Padding } from "@mui/icons-material";
import { Container } from "@mui/material";
import { ClockIcon } from "@mui/x-date-pickers";
import React, { useState } from "react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch, useSelector } from "react-redux";
import Login from "./Tabs/Login";
import ApiResult from "../CommonWidgets/ApiResult";
import { setCart, setLogin } from "../state";
function MenuHeader({
  image,
  opening,
  closing,
  address,
  primaryColor,
  secondaryColor,
  tabLocation,
  handleChangeTabLocation,
  map_location
}) {
  const dispatch = useDispatch();
  const isAuth = Boolean(useSelector((state) => state.token));
  const customer = useSelector((state) => state.customer);
  const [isLoginVisible, setIsLoginVisible] = useState(false);

  const [isLoginSuccess, setIsLoginSuccess] = useState(false);
  const [isMenuVisible, setIsMenuVisible] = useState(false);

  const isFirstTime = useSelector((state) => state.isFirstTime);

  const handleLogout = () => {
    dispatch(
      setLogin({
        token: null,
        customer: null,
      })
    );

    dispatch(
      setCart({
        cart: [],
      })
    );
  };

  return (
    <>
      {isLoginVisible ? (
        <Login
        isFirstTime={isFirstTime}
          companyLogo={image}
          handleOnClose={(success) => {
            if (success) {
              setIsLoginSuccess(true);
            }
            setIsLoginVisible(false);
          }}
        />
      ) : null}

      {isLoginSuccess ? (
        <ApiResult
          message={"Logged in succesfully"}
          handleClose={() => setIsLoginSuccess(false)}
          isSuccess
        />
      ) : null}

      <div
        className="menu-header w-full z-20"
        style={{
          background: primaryColor,
          color: secondaryColor,
          boxShadow: "0 2px 5px gray",
          position: "sticky",
          width: "100%",
          top:"0"
        }}
      >
        <Container sx={{ backgroundColor: primaryColor }}>
          <div className="top" style={{ padding: "1rem 0" }}>
            <div className="left">
              <LocationOnIcon className="" /> <a className=" cursor-pointer" href={map_location} target="_black">{address}</a> <span>|</span>
              <ClockIcon /> {String(opening).slice(0, 5)}-
              {String(closing).slice(0, 5)}
            </div>
            <div className="flex gap-4">
              <div className="right">
                {" "}
                {/* <SearchIcon /> */}
              </div>

              {/* {isAuth ? (
                <div className="welcome-text">
                  <p>Hi, {customer.name}</p>
                </div>
              ) : null} */}
            </div>
          </div>
          <hr
            style={{
              borderTop: `0.5px solid ${secondaryColor}`,
              borderBottom: "none",
              borderLeft: "none",
              borderRight: "none",
            }}
          />
          <div className="bottom h-28" style={{ padding: ".2rem 0" }}>
            <div
              className="left h-5/6"
              style={{ borderRadius: "10px", margin: "1rem 0px" }}
            >
              <img
                className="h-full"
                src={image}
                alt="company-logo"
                height={"70px"}
                style={{ borderRadius: "10px" }}
              />
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <div
                className={!isMenuVisible ? "menu-icon" : "--display-none"}
                onClick={() => setIsMenuVisible(true)}
              >
                <Menu />
              </div>

              <div
                className={isMenuVisible ? "burger-menu" : "--display-none"}
                style={{}}
              >
                <ul
                  className="menu-links"
                  style={{
                    display: "flex",
                    gap: "1.5rem",
                    flexDirection: "column",
                    backgroundColor: primaryColor,
                  }}
                >
                  <div
                    className="menu-close-icon"
                    onClick={() => setIsMenuVisible(false)}
                  >
                    <Close />
                  </div>
                  <li
                    className={
                      tabLocation == "menu"
                        ? `underline underline-offset-8 decoration-1 cursor-pointer`
                        : `cursor-pointer`
                    }
                    style={{
                      textDecoration:
                        tabLocation == "menu" && "underline" + secondaryColor,
                      textDecorationThickness: "2px",
                      // textUnderlineOffset: "8px",
                    }}
                    onClick={() => {
                      handleChangeTabLocation("menu");
                      setIsMenuVisible(false);
                    }}
                  >
                    MENU
                  </li>
                  <li className={
                      tabLocation == "reservation"
                        ? `underline underline-offset-8 decoration-1 cursor-pointer`
                        : `cursor-pointer`
                    }
                    onClick={() => {
                      handleChangeTabLocation("reservation");
                      setIsMenuVisible(false);
                    }}>RESERVATION</li>
                  <li
                    className={
                      tabLocation == "cart"
                        ? `underline underline-offset-8 decoration-1 cursor-pointer`
                        : `cursor-pointer`
                    }
                    onClick={() => {
                      handleChangeTabLocation("cart");
                      setIsMenuVisible(false);
                    }}
                  >
                    CART
                  </li>
                  {isAuth ? (
                    <>
                      {/* <li className={`cursor-pointer`}>ORDER HISTORY</li> */}
                      <li
                        className={`cursor-pointer`}
                        onClick={() => {
                          setIsMenuVisible(false);

                          handleLogout();
                        }}
                      >
                        LOGOUT
                      </li>
                    </>
                  ) : (
                    <li
                      className={`cursor-pointer`}
                      onClick={() => {
                        setIsMenuVisible(false);

                        setIsLoginVisible(true);
                      }}
                    >
                      {isFirstTime ? "SIGNUP" : "LOGIN"}
                    </li>
                  )}
                </ul>
              </div>
            </div>
            <div className="right">
              <ul
                className="menu-links"
                style={{
                  display: "flex",
                  gap: "1.5rem",
                }}
              >
                <div
                  className={
                    isMenuVisible ? "close-menu-icon" : "--display-none"
                  }
                ></div>
                <li
                  className={
                    tabLocation == "menu"
                      ? `underline underline-offset-8 decoration-1 cursor-pointer`
                      : `cursor-pointer`
                  }
                  style={{
                    textDecoration:
                      tabLocation == "menu" && "underline" + secondaryColor,
                    textDecorationThickness: "2px",
                    // textUnderlineOffset: "8px",
                  }}
                  onClick={() => handleChangeTabLocation("menu")}
                >
                  MENU
                </li>
                <li className={
                      tabLocation == "reservation"
                        ? `underline underline-offset-8 decoration-1 cursor-pointer`
                        : `cursor-pointer`
                    }
                    onClick={() => {
                      handleChangeTabLocation("reservation");
                      setIsMenuVisible(false);
                    }}>RESERVATION</li>
                <li
                  className={
                    tabLocation == "cart"
                      ? `underline underline-offset-8 decoration-1 cursor-pointer`
                      : `cursor-pointer`
                  }
                  onClick={() => handleChangeTabLocation("cart")}
                >
                  CART
                </li>
                {isAuth ? (
                  <>
                    {/* <li className={`cursor-pointer`}>ORDER HISTORY</li> */}
                    <li
                      className={`cursor-pointer`}
                      onClick={() => {
                        handleLogout();
                      }}
                    >
                      LOGOUT
                    </li>
                  </>
                ) : (
                  <li
                    className={`cursor-pointer`}
                    onClick={() => {
                      setIsLoginVisible(true);
                    }}
                  >
                     {isFirstTime ? "SIGNUP" : "LOGIN"}
                  </li>
                )}
              </ul>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}

export default MenuHeader;
