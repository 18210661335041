import { Close } from "@mui/icons-material";
import { Button, Dialog } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomTextfield from "../../CommonWidgets/CustomTextfield";
import axios from "../../api/axios";
import { URLs } from "../../api/URLs";
import {
  loginCustomer,
  registerCustomer,
  checkPhone,
  forgotPassword,
} from "../../Service/Menu_Services";
import { setLogin, setCart, setIsFirstTime } from "../../state/index";
import { useDispatch } from "react-redux";
import ApiResult from "../../CommonWidgets/ApiResult";
import ConfirmActionByOtp from "../../Components/ConfirmActionByOtp/ConfirmActionByOtp";
import { useNavigate } from "react-router-dom";

function ChangePasswordView({ onPasswordChange, phone,handleOnClose }) {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassowrd] = useState("");
  const [isError, setIsError] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const dispatch = useDispatch();

  const [isOtpConfirmationVisible, setIsOtpConfirmationVisible] =
    useState(false);
  const [isOtpFailure, setIsOtpFailure] = useState(false);
  const [isChangePasswordVisible, setIsChangePasswordVisible] = useState(false);
  const navigate = useNavigate();

  const handleForgotPassword = async (otp) => {
    // setIsLoading(true);
    await forgotPassword({ phone, newPassword, otp:otp })
      .then((result) => {
        console.log(result, "forgot password");

        dispatch(
          setLogin({
            token: result?.token,
            customer: result.customer,
          })
        ); 
        handleOnClose(false)

        // handleOnClose(true);
        // dispatch(
        //   setIsFirstTime({
        //     isFirstTime: false,
        //   })
        // );
      })
      .catch((err) => {
        // console.log(e)

        if (!err?.response) {
          setErrorMsg("No Server Response");
        } else if (
          err.response?.status === 409 ||
          err.response?.status === 401
        ) {
          setErrorMsg(err.response?.data?.msg);
        } else {
          setErrorMsg("Register Failed");
        }
        setIsError(true);
      });
  }; 
  return (
    <div className="w-full flex flex-col py-4 bg-color-whites"> 
      {isOtpFailure ? (
        <ApiResult
          message={errorMsg}
          handleClose={() => setIsOtpFailure(false)}
        />
      ) : null}
      {isOtpConfirmationVisible ? (
        <ConfirmActionByOtp
          handleOnClose={() => {
            setIsOtpConfirmationVisible(false);
          }}
          // primaryColor={primaryColor}
          // secondaryColor={secondaryColor}
          action={handleForgotPassword}
          phone={phone}
          actionText={"Chang Password"}
        />
      ) : null}

      {}
      <div className="py-2 px-8">
        <CustomTextfield
          required={true}
          label={"New Password"}
          value={newPassword}
          onChange={(e) => {
            if (e.target.value.length < 11) setNewPassword(e.target.value);
          }}
        />
      </div>
      <div className="py-2 px-8">
        <CustomTextfield
          required={true}
          label={"Confirm Password"}
          value={confirmPassword}
          onChange={(e) => {
            if (e.target.value.length < 11) {
              setConfirmPassowrd(e.target.value);
            }
          }}
        />

        {isError??(<p className="text-xs">{errorMsg}</p>)}
        <br/>
        <Button
          disabled={
            newPassword.length<8 || newPassword!==confirmPassword
          }
          onClick={() => setIsOtpConfirmationVisible(true)}
          sx={{
            width: "fit-content",
            padding: 2,
            fontSize: ".7rem",
          }}
        >
         Verify By OTP
        </Button>
      </div>
    </div>
  );
}

export default ChangePasswordView;
