import { Close } from "@mui/icons-material";
import { Button, Dialog } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomTextfield from "../../CommonWidgets/CustomTextfield";
import axios from "../../api/axios";
import { URLs } from "../../api/URLs";
import {
  loginCustomer,
  registerCustomer,
  checkPhone,
  forgotPassword,
} from "../../Service/Menu_Services";
import { setLogin, setCart, setIsFirstTime } from "../../state/index";
import { useDispatch } from "react-redux";
import ApiResult from "../../CommonWidgets/ApiResult";
import ConfirmActionByOtp from "../../Components/ConfirmActionByOtp/ConfirmActionByOtp";
import ChangePasswordView from "./ChangePassword";

function Login({ isOpen, handleOnClose, companyLogo, isFirstTime }) {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [fullName, setFullName] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("Something went wrong try again");
  const [isLogin, setIsLogin] = useState(!isFirstTime);

  const [isPhoneRegistered, setIsPhoneRegistered] = useState(false);

  const [isOtpSent, setIsOtpSent] = useState(false);
  const [remainingTime, setRemainingTime] = useState(0);
  const [isCounting, setIsCounting] = useState(false);
  const [otp, setOTP] = useState("");

  const dispatch = useDispatch();
  useEffect(() => {
    if (isCounting) {
      const intervalId = setInterval(() => {
        const diff = remainingTime - 1000;
        setRemainingTime(diff);
        if (diff < 1000) {
          setIsCounting(false);
        }
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [remainingTime]);

  const hours = Math.floor(remainingTime / (1000 * 60 * 60));
  const minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);

  const handleSendOtp = async () => {
    await axios
      .post(
        URLs.SEND_OTP,
        JSON.stringify({
          phone: phoneNumber,
          token: "generated_token",
        }),
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      .then((rez) => {
        console.log(rez);
        if (rez.data.status) {
          console.log(rez.data.status, " is the result of otp");
          setIsOtpSent(true);
          setRemainingTime(2000 * 60);
        } else {
          setIsOtpSent(true);
          setRemainingTime(rez.data.msg);
        }
        setIsCounting(true);
      })
      .catch((e) => console.log("error while sending otp"));
  };

  const handleLogin = async () => {
    setIsLoading(true);
    await loginCustomer({ phoneNumber, password })
      .then((result) => {
        console.log(result, "shame");
        dispatch(
          setCart({
            cart: result?.cart,
          })
        );
        dispatch(
          setLogin({
            token: result?.token,
            customer: result.customer,
          })
        );
        setIsLoading(false);

        handleOnClose(true);

        dispatch(
          setIsFirstTime({
            isFirstTime: false,
          })
        );
      })
      .catch((err) => {
        // console.log(e)

        if (!err?.response) {
          setErrorMsg("No Server Response");
        } else if (
          err.response?.status === 409 ||
          err.response?.status === 401
        ) {
          setErrorMsg(err.response?.data.msg);
        } else {
          setErrorMsg("Login failed");
        }
        setIsError(true);
      });
  };

  const handleregisterCustomer = async () => {
    setIsLoading(true);
    await registerCustomer({ phoneNumber, password, name: fullName, otp:otp })
      .then((result) => {
        console.log(result, "regiter customer");

        dispatch(
          setLogin({
            token: result?.token,
            customer: result.customer,
          })
        );
        setIsLoading(false);

        handleOnClose(true);
        dispatch(
          setIsFirstTime({
            isFirstTime: false,
          })
        );
      })
      .catch((err) => {
        // console.log(e)

        if (!err?.response) {
          setErrorMsg("No Server Response");
        } else if (
          err.response?.status === 409 ||
          err.response?.status === 401
        ) {
          setErrorMsg(err.response?.data?.msg);
        } else {
          setErrorMsg("Register Failed");
        }
        setIsError(true);
      });
  };
  const handleCheckPhoneNumber = async (phone) => {
    // setIsLoading(true);
    console.log("jelo");

    await checkPhone({ phone: phone })
      .then((result) => {
        console.log(result);

        setIsPhoneRegistered(false);
      })
      .catch((err) => {
        console.log(err);

        if (!err?.response) {
          setErrorMsg("No Server Response");
          setIsError(true);
        }

        if (err.response?.status === 409) {
          setIsPhoneRegistered(true);
        }
      });
  };

  const checkingFun = () => {
    console.log("chekcing function");
  };

  
  const [isOtpConfirmationVisible, setIsOtpConfirmationVisible] =
  useState(false);
  const [isOtpFailure, setIsOtpFailure] = useState(false);
  const [isChangePasswordVisible, setIsChangePasswordVisible] = useState(false);
  return (
    <div>
    
      {isOtpFailure ? (
        <ApiResult
          message={"Phone authentication failed"}
          handleClose={() => setIsOtpFailure(false)}
        />
      ) : null}
       {/* {isOtpConfirmationVisible ? (
        <ConfirmActionByOtp
          handleOnClose={() => {
            setIsOtpConfirmationVisible(false);
          }}
          // primaryColor={primaryColor}
          // secondaryColor={secondaryColor}
          action={handleForgotPassword}
        />
      ) : null} */}
      {isError ? (
        <ApiResult message={errorMsg} handleClose={() => setIsError(false)} />
      ) : null}
      <Dialog
        closeOnEscape={true}
        open={true}
        fullWidth
        PaperProps={{ sx: { height: "fit-content", borderRadius: "8px" } }}
      >
       
          <div className="w-full flex flex-col py-4 bg-color-whites">
          <div className="pt-1 pb-4 px-4">
            <div className="flex flex-row justify-end">
              {" "}
              <Close
                onClick={() => handleOnClose(false)}
                className="cursor-pointer"
              />
            </div>
          </div>
          <hr className="pb-2" />
          {
          isChangePasswordVisible?
          <ChangePasswordView
        handleOnClose={handleOnClose}

            phone={phoneNumber}
          />:<>
          <div className="px-4 w-auto h-auto  flex flex-col justify-center items-center overflow-hidden">
            <img className="max-h-40" src={companyLogo}></img>
          </div>
          <div className="pt-4 pb-2 flex justify-center items-center text-lg font-bold">
            Enter your credentials
          </div>
          {/* <form onSubmit={handleLogin}> */}

          {!isLogin && (
            <div className="py-2 px-8">
              <CustomTextfield
                required={true}
                label={"Name"}
                value={fullName}
                onChange={(e) => {
                  if (e.target.value.length < 11) setFullName(e.target.value);
                }}
              />
            </div>
          )}
          <div className="py-2 px-8">
            <CustomTextfield
              required={true}
              label={"Phone Number"}
              value={phoneNumber}
              onChange={(e) => {
                if (e.target.value.length < 11) {
                  setPhoneNumber(e.target.value);
                }

                if (e.target.value.length === 10 && !isLogin) {
                  handleCheckPhoneNumber(e.target.value);
                  // checkingFun()
                }
              }}
            />
            {isPhoneRegistered && !isLogin && (
              <p className="text-xs">
                Phone number already registered with an account
              </p>
            )}
          </div>
          {!isLogin?
            <div className="py-2 px-8 flex items-center gap-2">
            <CustomTextfield
              required={true}
              label={"OTP"}
              placeholder={"OTP recieved from"}
              value={otp}
              onChange={(e) => {
                if (e.target.value.length < 11) setOTP(e.target.value);
              }}
            />
            <div className="p-2 w-20 flex justify-center border-gray border-2 rounded-lg">
              {isCounting ? (
                <div> {`${minutes} : ${seconds} `}</div>
              ) : (
                <Button
                  disabled={
                    isPhoneRegistered || String(phoneNumber).trim().length != 10
                  }
                  onClick={() => handleSendOtp()}
                  sx={{
                    width: "fit-content",
                    padding: 0,
                    fontSize: ".7rem",
                  }}
                >
                  Send OTP
                </Button>
              )}
            </div>
          </div>:null}
          <div className="py-2 px-8">
            <CustomTextfield
              type={"password"}
              required={true}
              label={"Password"}
              value={password}
              onChange={(e) => {
                if (e.target.value.length < 49) setPassword(e.target.value);
              }}
            />
          </div>
          {(isLogin&& phoneNumber.length == 10)&&<div className="py-2 px-8 flex justify-center">
             
              <p>
                {/* Don't have an account yet?{" "} */}
                <span
                  onClick={() => setIsChangePasswordVisible(true)}
                  className="text-blue-300 cursor-pointer"
                >
                  Forgot Password.
                </span>
              </p>
            
          </div>}
          {!isLogin && (
            <div className="py-2 px-8">
              <CustomTextfield
                type={"password"}
                required={true}
                label={"Confirm Password"}
                value={confirmPassword}
                onChange={(e) => {
                  if (e.target.value.length < 49)
                    setConfirmPassword(e.target.value);
                }}
              />
            </div>
          )}
          <div className="py-2 px-8 flex justify-end">
            {isLogin ? (
              <Button
                onClick={handleLogin}
                disabled={
                  String(phoneNumber).length < 10 || String(password) < 8
                }
              >
                LOGIN
              </Button>
            ) : (
              <Button
                onClick={handleregisterCustomer}
                disabled={
                  String(phoneNumber).length < 10 ||
                  String(password) < 8 ||
                  String(password) !== String(confirmPassword) ||
                  String(fullName) < 3
                }
              >
                REGISTER
              </Button>
            )}
          </div>
          <div className="py-2 px-8 flex justify-center">
            {isLogin ? (
              <p>
                Don't have an account yet?{" "}
                <span
                  onClick={() => setIsLogin(false)}
                  className="text-blue-300 cursor-pointer"
                >
                  Register.
                </span>
              </p>
            ) : (
              <p>
                Already have an account?{" "}
                <span
                  onClick={() => setIsLogin(true)}
                  className="text-blue-300 cursor-pointer"
                >
                  Login.
                </span>
              </p>
            )} 
          </div>
       
          </>
        }</div>
      </Dialog>
    </div>
  );
}

export default Login;
